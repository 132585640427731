import React from 'react'

import {SpinnerInfinity} from "spinners-react";



const Loader = () => {

    return (
        <SpinnerInfinity size={50} thickness={100} speed={100} color="#000000"
                                         secondaryColor="#FFE01B"/>
    );
}

export default Loader;